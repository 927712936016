<template>
  <app-card
    class="grid grid-cols-[auto_1fr_auto] items-start gap-4 p-4 transition-all !rounded"
    :class="{
      'cursor-pointer active:scale-[.97]': !disabled,
      'border-black shadow-relief-2 shadow-black': isActive,
      'opacity-50': disabled,
      'hover:shadow-lg': !isActive && !disabled,
    }"
    :data-cy-radio-card="value"
    outlined
    tag="label"
  >
    <slot name="prepend">
      <app-form-radio-bubble :active="isActive" class="mt-1" />
    </slot>

    <div class="overflow-hidden">
      <slot>
        <p class="font-semibold">
          {{ title }}
        </p>
        <p v-if="subtitle" class="text-sm text-subtle">
          {{ subtitle }}
        </p>
      </slot>
    </div>

    <slot name="append" v-bind="{ active: isActive }" />

    <input
      v-model="model"
      class="sr-only"
      :disabled="disabled"
      :name="name"
      type="radio"
      :value="value"
    />
  </app-card>
</template>

<script lang="ts" setup generic="T extends number | string">
import type { TranslateResult } from "vue-i18n";

const properties = defineProps<{
  modelValue?: T;
  value?: T;
  name: string;
  title?: TranslateResult;
  subtitle?: TranslateResult;
  disabled?: boolean;
}>();

const model = useVModel(properties, "modelValue");

const isActive = computed(() => properties.modelValue === properties.value);
</script>
